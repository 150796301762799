export default {
  data() {
    return {
      form: [
        {
          formId: 1, // not post
          disposalCompanyId: this.disposalCompanyId,
          disposalSiteName: "",
          addressInfo: {
            zipCode: "",
            prefecturesId: null,
            address: "",
            buildingName: "",
          },
          tel: "",
          fax: null,
          jwnetContractNo: "",
          ediKey: "",
          disposalStoreCode: "",
          publicConfirmationNo: "",
          disposalSiteLicenceList: [],
          disposalSiteData: [], // not post
          finalDisposalSiteList: [],
        },
      ],
      duplicationNameCheckInfoList: [],
    };
  },
};
